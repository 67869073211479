import React from 'react'
import './BookAParty.scss'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { graphql } from 'gatsby'

const BookAParty = ({ data }) => {
  return (
    <Layout>
      <div className="BookAParty">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Book a Party"
          caption="Host the best birthday party ever!"
          actionURL="/contact"
          actionText="Contact us to book"
        />
        <div className="container content">
          <p>
            The secret to a perfect party for your child is get them active and
            keep them moving! Running, climbing, jumping - so much fun!
          </p>
          <p>
            Our party hosts make kids laugh and feel great! They design a crazy,
            cool obstacle course for them to race through, teach them the proper
            techniques to use our obstacles and prepare them to be the next
            NINJA WARRIOR! You'll be Parent of the Year in no time flat!
          </p>

          <h2>Birthday Party Booking (Up to 20 Kids)</h2>
          <p>
            Looking for a one of a kind party in Ottawa's only indoor obstacle
            course gym? We have two party packages that take place during our
            open gym times Saturdays 10:30 am - 9:00 pm, Sundays 11:45 - 7:00 pm
          </p>
          <ListGroup>
            <ListGroupItem>
              <strong>
                Bday Party Package 1 (10 Person Pack- 1 Table Pack):
              </strong>
            </ListGroupItem>
            <ListGroupItem>
              1 Hour During Open Gym (You are invited to stay an additional
              after your party time slot)
            </ListGroupItem>
            <ListGroupItem>Includes 30 Minute Personal Trainer</ListGroupItem>
            <ListGroupItem>1 Pizza</ListGroupItem>
            <ListGroupItem>$195.00 + tax (220.35)</ListGroupItem>
          </ListGroup>

          <ListGroup>
            <ListGroupItem>
              <strong>
                Bday Party Package 2 (20 Person Pack - 2 Table Pack):
              </strong>
            </ListGroupItem>
            <ListGroupItem>
              1 Hour During Open Gym (You are invited to stay an additional
              after your party time slot)
            </ListGroupItem>
            <ListGroupItem>
              Includes 30 Minute Personal Trainer (2 Trainers split group)
            </ListGroupItem>
            <ListGroupItem>2 Pizzas</ListGroupItem>
            <ListGroupItem>$390 + Tax (440.70)</ListGroupItem>
          </ListGroup>

          <h2>Private Booking (up to 75 people)</h2>
          <p>
            Want the place to yourself? No problem! We offer private party
            bookings for up to 75 people.
          </p>
          <ListGroup>
            <ListGroupItem>
              2 Hours of exclusive access to the OCR Facility
            </ListGroupItem>
            <ListGroupItem>
              1 pizza for every 10 guests in attendance
            </ListGroupItem>
            <ListGroupItem>
              Games, team-building, unstructured play time and more!
            </ListGroupItem>
          </ListGroup>
          
          <p>Give us a call and speak to Josh for pricing inquiries.</p>
          <p>Book now while spots are available!</p>
          <p>
            <strong>Note:</strong> Any unused passes will remain on your account
            for future use.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default BookAParty

export const query = graphql`
  {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/09/children.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
